import { CoreMenu } from "@core/types";
import { Role } from "app/auth/models";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: "home",
    url: "apps/dashboard",
  },

  {
    id: "manage",
    title: "Quản lý đào tạo",
    type: "collapsible", // Thay đổi thành collapse để có thể hiển thị submenu
    icon: "package",
    children: [
      // Thêm danh sách các submenu
      {
        id: "educationSystem",
        title: "Hệ đào tạo",
        type: "item",
        icon: "briefcase",
        url: "apps/education-system",
      },
      {
        id: "department",
        title: "Khoa",
        type: "item",
        icon: "book-open",
        url: "apps/department",
      },
      {
        id: "major",
        title: "Ngành",
        type: "item",
        icon: "database",
        url: "apps/major",
      },
      {
        id: "specialization",
        title: "Chuyên ngành",
        type: "item",
        icon: "figma",
        url: "apps/specialization",
      },
      {
        id: "class",
        title: "Quản lý lớp học",
        type: "item",
        icon: "users",
        url: "apps/class",
      },
    ],
  },

  {
    id: "graduation-decision",
    title: "Quyết định tốt nghiệp",
    type: "item",
    icon: "book",
    url: "apps/graduation-decision",
  },
  {
    id: "document-type",
    title: "Quản lý loại tài liệu",
    type: "item",
    icon: "archive",
    url: "apps/document-type",
  },
  {
    id: "document-manage",
    title: "Quản lý tài liệu",
    type: "item",
    icon: "book-open",
    url: "apps/document-manage",
  },
  {
    id: "api-key",
    title: "Quản lý người dùng",
    type: "item",
    icon: "user-check",
    url: "apps/api-key",
    role: [Role.Admin, Role.SuperAdmin],
  },
  {
    id: "data-synchronization",
    title: "Đồng bộ dữ liệu",
    type: "item",
    icon: "git-merge",
    url: "apps/data-synchronization",
  },
  {
    id: "request-document",
    title: "Phê duyệt cập nhập tài liệu",
    type: "item",
    icon: "check-square",
    url: "apps/request-document",
    role: [Role.SuperAdmin]
  },
];

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { Router } from "@angular/router";
import { Role, User } from "app/auth/models";
import { environment } from "environments/environment";
import { KeycloakService } from "keycloak-angular";
import { DataResponse } from "app/models/DataResponse";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  //private
  public role: any;
  public currentUserSubject: BehaviorSubject<User>;
  public formBody;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _keycloak: KeycloakService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    window.location.reload();
  }
  getData() {
    this._http
      .get<DataResponse<User>>(`${environment.apiUrl}/user/userinfo`)
      .subscribe((res) => {
        // console.log(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
        this.currentUserSubject.next(res.data);
        this._router.navigate(["/apps/dashboard"]);
        return res.data;
      });
  }
  // getVarEnv() {
  //   const headers = new HttpHeaders().set("X-Vault-Token", "CMCATI@2023");
  //   this._http
  //     .get<any>(`https://sec.cmcati.vn/vault/v1/secret/data/cmeet-web`, {
  //       headers,
  //     })
  //     .subscribe((res) => {

  //       return res;
  //     });
  // }
  getUserInfo() {
    return this._http.get<DataResponse<any>>(
      `${environment.apiUrl}/user/userinfo`
    );
  }
  async setCurrentUser() {
    localStorage.removeItem("currentUser");
    //Lấy thông tin user , dùng await và toPromise hoặc subscribe
    await this.getUserInfo()
      .toPromise()
      .then((res) => {
        // console.log(res);
        const roles =
          this._keycloak.getKeycloakInstance().tokenParsed?.active_tenant
            ?.roles;

        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            ...res.data,
            role: roles.includes(Role.SuperAdmin)
              ? Role.SuperAdmin
              : roles.includes(Role.Admin)
              ? Role.Admin
              : Role.User,
          })
        );
        this.role = JSON.parse(localStorage.getItem("currentUser"));
        // localStorage.setItem("role", this.role.role);
        this.currentUserSubject.next({
          ...res.data,
          role: roles.includes(Role.SuperAdmin)
            ? Role.SuperAdmin
            : roles.includes(Role.Admin)
            ? Role.Admin
            : Role.User,
        });
        if (this.currentUserValue.isNewUser) {
          this._router.navigate(["/pages/authentication/update-user-infor"]);
        }
        // this._router.navigate(["/apps/dashboard"]);
        return;
      });
  }
  getEnvironmentByVault(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "X-Vault-Token": "CMCATI@2023",
    });

    return this._http.get(
      "https://sec.cmcati.vn/v1/v1/secret/data/cmeet-web-product",
      { headers: headers }
    );
  }
  getKeyMeeting(): Observable<any> {
    const token = JSON.parse(localStorage.getItem("token"));
    let headers = new HttpHeaders();
    if (token) {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    }
    const params = new HttpParams()
      .set("isPaginate", false)
      .set("search", "")
      .set("status", 1);
    return this._http.get(`${environment.apiUrl}/meeting-session`, {
      headers: headers,
      params,
    });
  }
}
